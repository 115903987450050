<template>
  <div :style="{background: is_mobile ? '#000' : 'transparent'}">
    <img :src="header_img" style="width: 100%; display: block" />
    <div :style="{width: is_mobile ? '95%' : '60%', margin: '0 auto', padding: '3rem 0'}">
      <Carousel :per-page="1" :navigate-to="active_slide" :mouse-drag="false" :touch-drag="false" :paginationEnabled="false">
        <Slide v-for="value, key in slides" :key="key">
          <component :is="value.component" v-bind="value.params" v-model="value.answer" :show_errors="show_errors && active_slide==key" />
        </Slide>
        <slide>
          <div style="display: flex; align-items: center; justify-content: center; text-align: center;font-size: 5rem; height: 100% ">{{ $t('Paldies par dalību!') }}</div>
        </slide>
      </Carousel>
      <div style="overflow: auto; margin-top: 3rem; text-align: center" v-if="active_slide<slides.length">
        <div :style="{display: is_mobile ? 'block' : 'inline-block'}">
          <div v-for="i in slides.length" :key="i" :class="{bubble: true, filled: i-1<=active_slide}" ></div>
        </div>

        <div class="btn btn-prev" @click="goPrev">{{ $t('Atpakaļ') }}</div>

        <div class="btn btn-next" @click="goNext" v-if="active_slide+1<slides.length">{{ $t('Tālāk') }}</div>
        <div class="btn btn-next" @click="goNext" v-else>{{ this.saving ? $t('Saglabā') : $t('Nosūtīt') }}</div>



      </div>
    </div>
    <img :src="footer_img" style="width: 100%; display: block" />
  </div>
</template>

<script>
import { Slide, Carousel } from 'vue-carousel';
import Question from "@/components/Question";
import TextQuestions from "@/components/TextQuestions";
import axios from 'axios';

export default {
  name: "Questionarie",
  data() {
    return {
      is_mobile: false,
      saving: false,
      active_slide: 0,
      show_errors: false,
      slides: [
        {
          'component': Question,
          'key': 'Q1',
          'params': {
            'title': 'Kāda veida kafiju Jūs parasti baudāt mājās? (iespējamas vairākas atbildes)',
            'options': ['Malto', 'Pupiņas', 'Šķīstošo', 'Kapsulas', 'Nelietoju kafiju'],
            'multiple': true,
            'other': false
          },
          'answer': null
        },
        {
          'component': Question,
          'key': 'Q2',
          'params': {
            'title': 'Kādus kafijas dzērienus Jūs parasti gatavojat mājās?',
            'options': ['Espresso', 'Melnu kafiju', 'Melnu kafiju ar pienu/krējumu', 'Kapučīno', 'Latte'],
            'multiple': true,
            'other': true
          },
          'answer': null
        },
        {
          'component': Question,
          'key': 'Q3',
          'params': {
            'title': 'Vai Jums ir kafijas automāts, lai pagatavotu kafiju no pupiņām?',
            'options': ['Jā', 'Nav', 'Nav, bet plānoju iegādāties', 'Nav un neplānoju iegādāties'],
            'multiple': false,
            'other': false
          },
          'answer': null
        },
        {
          'component': Question,
          'key': 'Q4',
          'params': {
            'title': 'No kāda veida pupiņām parasti tiek pagatavots kapučīno/latte?',
            'options': ['Espresso', 'Arabika', 'Crema', 'Cits maisījums'],
            'multiple': false,
            'other': false
          },
          'answer': null
        },
        {
          'component': Question,
          'key': 'Q5',
          'params': {
            'title': 'Kas Jums pietrūkst, lai mājās baudītu kafijas dzērienu, kas būtu līdzvērtīgs kafejnīcā pagatavotam?',
            'options': ['Zināšanas/pieredze', 'Laba kafija', 'Pareiza mašīna', 'Nekas nepietrūkst'],
            'multiple': true,
            'other': true
          },
          'answer': null
        },
        {
          'component': TextQuestions,
          'key': 'Contact',
          'params': {
            'title': 'Kontaktinformācija',
            'fields': ['Vārds', 'Uzvārds', 'E-pasts', 'Telefons']
          },
          'answer': null
        },
      ]
    }
  },
  components: {
    Carousel,
    Slide,
    // Q2
  },

  mounted() {
    this.is_mobile = window.innerWidth <= 800
    window.onresize = () => {
      this.is_mobile = window.innerWidth <= 800
    }
  },
  computed: {
    footer_img() {
      return this.is_mobile ? require('@/assets/mobile_footer.jpg') : require('@/assets/footer.jpg')
    },
    header_img() {
      // return this.is_mobile ? require('@/assets/mobile_header_'+this.$i18n.locale+'.jpg') : require('@/assets/header_'+this.$i18n.locale+'.jpg')
      return require('@/assets/header_'+this.$i18n.locale+'.jpg')
    }
  },
  methods: {
    save() {
      this.saving = true
      axios.post(
          '/save.php',
          {
            'answer': JSON.stringify(this.slides),
            'language': this.$i18n.locale
          }
      ).then(resp => {
        if(resp.data==='OK')
          this.active_slide += 1
        else
          alert(this.$t('Sistēmas kļūda! Mēģiniet vēlreiz vēlāk.'))
      }).catch(() => {
        alert(this.$t('Sistēmas kļūda! Mēģiniet vēlreiz vēlāk.'))
      }).then(() => {
        this.saving = false
      })

    },
    goNext() {
      if(this.slides[this.active_slide].answer && this.slides[this.active_slide].answer.valid)
      {
        this.show_errors = false
        if(this.active_slide+1===this.slides.length)
          this.save()
        else
          this.active_slide += 1
      }
      else
      {
        this.show_errors = true
      }

    },
    goPrev() {
      if(this.active_slide === 0)
        this.$emit('cancel')
      else
        this.active_slide -= 1
    }

  }
}
</script>

<style>
  .slide_title {
    margin: 0 auto;
    font-size: 2.5rem;
    text-align: center;
  }

  .slide_questions {
    width: 80%;
    font-size: 1.8rem;
    margin: 1rem auto;
  }

  .btn {
    text-transform: uppercase;
    width: 10rem;
    cursor: pointer;
    padding: 0.5rem;
    text-align: center;
    background: #968772;
  }

  .bubble {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 0.5rem;
    border: solid 0.1rem #968772;
    margin: 1rem 1rem;
  }

  .bubble.filled {
    background: #968772;
  }

  .btn-prev {
    float: left;
  }

  .btn-next {
    float: right;
  }
</style>

<template>
  <div>
    <div class="slide_title">{{ $t(title) }}</div>
    <div>
      <div v-for="field in fields" :key="field" :class="['answer', show_errors && !status[field] ? 'errors' : null]">
        <input type="text" :placeholder="$t(field)" :value="status[field]" @input="setValue(field, $event)" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TextQuestions",
  props: {
    value: {
      default: null,
      type: Object
    },
    title: {
      default: null,
      type: String
    },
    fields: {
      default: null,
      type: Array
    },
    show_errors: {
      default: false,
      type: Boolean
    },
  },
  computed: {
    status: {
      get() {
        if (this.value)
          return this.value

        const status = {}
        for(let field of this.fields) {
          status[field] = ''
        }

        return status
      },
    }
  },
  methods: {
    setValue(field, event) {
      const new_status = {...this.status}
      new_status[field] = event.target.value
      this.$emit('input', this.checkStatus(new_status))
    },
    checkStatus(status) {
      status.valid = true
      for(let field of this.fields)
        status.valid = status.valid && !!status[field]

      return status
    }
  }
}
</script>

<style scoped>
  .errors {
    color: red;
  }

  .answer {
    display: block;
    cursor: pointer;
    border: solid 0.1rem #fff;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 1.5rem;
    text-align: center;
  }

  .answer.selected {
    background: #fff;
    color: #000;
  }


  .answer input[type=text] {
    background: transparent;
    border: none;
    font-size: inherit;

    border-bottom: 1px #fff;
    color: inherit;
    text-align: center;
    font-family: inherit;
    display: block;
    width: 100%;
  }

  .answer input[type=text]::placeholder {
    color: inherit;
    text-decoration: inherit;
    font-style: inherit;
    font-family: inherit;
  }
</style>

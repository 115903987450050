<template>
  <div>
    <div class="slide_title">{{ $t(title) }}</div>
    <div :class="['slide_questions', has_errors ? 'errors' : null]">
<!--      <label v-for="option in options" :key="option" class="answer"><input type="checkbox" :value="option" v-model="option_answers" /> {{option}}</label>-->
      <div v-for="option in options" :key="option" :class="['answer', isChecked(option) ? 'selected' : null]" @click="setValue(option)">{{ $t(option) }}</div>
      <div v-if="other" :class="['answer', other_answer ? 'selected' : null]"><input v-model="other_answer" type="text" :placeholder="$t('Cits (lūdzu norādiet)')" /></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Question",
  props: {
    value: {
      default: null,
      type: Object
    },
    title: {
      default: null,
      type: String
    },
    options: {
      default: null,
      type: Array
    },
    show_errors: {
      default: false,
      type: Boolean
    },
    multiple: {
      default: false,
      type: Boolean
    },
    other: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    has_errors() {
      return this.show_errors && (!this.value || !this.value.valid)
    },
    status: {
      get() {
        if (this.value)
          return this.value

        const status = {}
        status['checked'] = this.multiple ? [] : null

        if(this.other)
          status['other'] = ''

        return status
      },
    },
    option_answers: {
      get() {
        return this.status.checked
      },
      set(val) {
        let new_val = null
        if(this.multiple)
        {
          if (this.isChecked(val))
            new_val = this.status.checked.filter(v => v !== val)
          else
          {
            new_val = [...this.status.checked]
            new_val.push(val)
          }
        }
        else
        {
          if (this.isChecked(val))
            new_val = null
          else
            new_val = val
        }

        this.$emit('input', this.checkStatus({...this.status, 'checked': new_val}))
      }
    },
    other_answer: {
      get() {
        return this.status.other
      },
      set(val) {
        this.$emit('input', this.checkStatus({...this.status, 'other': val}))
      }
    }
  },
  methods: {
    setValue(val) {
      let new_val = null
      if(this.multiple)
      {
        if (this.isChecked(val))
          new_val = this.status.checked.filter(v => v !== val)
        else
        {
          new_val = [...this.status.checked]
          new_val.push(val)
        }
      }
      else
      {
        if (this.isChecked(val))
          new_val = null
        else
          new_val = val
      }

      this.$emit('input', this.checkStatus({...this.status, 'checked': new_val}))
    },
    isChecked(val) {
      return !!(this.multiple && this.status.checked.includes(val) || !this.multiple && val===this.status.checked)
    },
    checkStatus(status) {
      status.valid = !!((status.checked && status.checked.length > 0) || (this.other && status.other));

      return status
    }
  }
}
</script>

<style scoped>
  .errors {
    color: red;
  }

  .answer {
    display: block;
    cursor: pointer;
    border: solid 0.1rem #fff;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 1.5rem;
    text-align: center;
  }

  .answer.selected {
    background: #fff;
    color: #000;
  }


  .answer input[type=text] {
    background: transparent;
    border: none;
    font-size: inherit;

    border-bottom: 1px #fff;
    color: inherit;
    text-align: center;
    font-family: inherit;
    display: block;
    width: 100%;
  }

  .answer input[type=text]::placeholder {
    color: inherit;
    text-decoration: inherit;
    font-style: inherit;
    font-family: inherit;
  }
</style>

<template>
  <div id="app">
    <div class="header">
      <a :href="'./rules_'+this.$i18n.locale+'.pdf'" target="_blank">{{ $t('Noteikumi')}}</a>
<!--      <a :href="'./winners_'+this.$i18n.locale+'.pdf'" target="_blank">{{ $t('Laimētāji')}}</a>-->
    </div>
    <Landing v-if="show_landing" @start="show_landing=false" />
    <Questionarie v-else @cancel="show_landing=true" />
    <div class="footer">{{ $t('Periods')}}</div>
  </div>
</template>

<script>


import Questionarie from "@/components/Questionarie";
import Landing from "@/components/Landing";

export default {
  name: 'App',
  data() {
    return {
      show_landing: true
    }
  },
  components: {
    Questionarie,
    Landing
  }
}
</script>

<style>
@font-face {font-family: "Verleg";
  font-weight: 700;
  src: url('./assets/fonts/Verlag-Black.otf');
  src: url('./assets/fonts/Verlag-Black.otf'),
  url("./assets/fonts/Verlag-Black.woff2") format("woff2"), /* chrome、firefox */
  url("./assets/fonts/Verlag-Black.woff") format("woff"), /* chrome、firefox */
  url("./assets/fonts/Verlag-Black.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}
@font-face {
  font-family: 'Verleg';
  font-weight: 300;
  src: url('./assets/fonts/Verlag-Light.otf');
  src: url('./assets/fonts/Verlag-Light.otf'),
  url('./assets/fonts/Verlag-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('./assets/fonts/Verlag-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Verleg';
  font-weight: 400;
  src: url('./assets/fonts/Verlag-Book.otf');
  src: url('./assets/fonts/Verlag-Book.otf'),
  url('./assets/fonts/Verlag-Book.woff') format('woff'), /* Pretty Modern Browsers */
  url('./assets/fonts/Verlag-Book.ttf')  format('truetype'); /* Safari, Android, iOS */
}

body, html {
  font-family: Verleg, serif;
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
}

html {
  font-size: 10px;
}

body {
  /*background-image: url("./assets/bg.jpg");*/
  /*background-repeat: repeat-y;*/
  /*background-size: 100% auto;*/
  background: #06080a;
  font-size: 1.8rem;
}

.header, .footer {
  text-align: center;
  background: #000;
  padding: 0.5rem 0;
}

.footer {
  padding-bottom: 3rem;
  font-weight: 300;
}

a {
  display: inline-block;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  color: #fff !important;
}




</style>
